import React, { useRef, useLayoutEffect, useEffect } from "react";
import { useSelector } from "react-redux";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5themes from "@amcharts/amcharts5/themes/Animated"

import trendForecastConnector from "components/Freeform Analytics/Trend Forecasting/connector";
import freeformConnector from "components/Freeform Analytics/Freeform Chart/connector";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFilePdf,
  faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

const useStyles = makeStyles({
  chartdiv: {
    width: "100%",
    height: "500px",
    maxWidth: "100%"
  }
});

let chartData = {};
var root = {};
var chart = {};
var xAxis = {};
var yAxis;
var series;
var legend = {};
let lastData;

const TSChart = (props) => {
  console.log('TSChart props: ', props);
  
  useLayoutEffect(() => {
    root[props.name] = am5.Root.new(props.name);
    root[props.name]._logo.dispose();
    const myTheme = am5.Theme.new(root);
    myTheme.rule("AxisLabel", ["minor"]).setAll({
      dy:1
    });
    myTheme.rule("Grid", ["x"]).setAll({
      strokeOpacity: 0.05
    });
    myTheme.rule("Grid", ["x", "minor"]).setAll({
      strokeOpacity: 0.05
    });
    
    // // Set themes
    // // https://www.amcharts.com/docs/v5/concepts/themes/
    // root.setThemes([
    //   am5.Theme.new(root),
    //   myTheme
    // ]);

    // Create a ColorSet
    const colorSet = am5.ColorSet.new(root[props.name], {
      // step: 1, // Adjust step to change color distance
      colors: [
        am5.color(0x1f77b4),  // Blue
        am5.color(0xff7f0e),  // Orange
        am5.color(0x2ca02c),  // Green
        am5.color(0xd62728),  // Red
        am5.color(0x9467bd),  // Purple
        am5.color(0x8c564b),  // Brown
        am5.color(0xe377c2),  // Pink
        am5.color(0x7f7f7f),  // Gray
        am5.color(0xbcbd22),  // Yellow-Green
        am5.color(0x17becf),  // Cyan
        am5.color(0xffbb78),  // Light Orange
        am5.color(0x98df8a),  // Light Green
        am5.color(0xc5b0d5),  // Light Purple
        am5.color(0xc49c94),  // Light Brown
      ]
    });
    
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    chart[props.name] = root[props.name].container.children.push(am5xy.XYChart.new(root[props.name], {
      panX: true,
      panY: true,
      wheelX: "panX",
      wheelY: "zoomX",
      maxTooltipDistance: 0,
      pinchZoomX:true,
      layout: root[props.name].verticalLayout
    }));
    
    
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var value = 100;
    
    function generateData() {
      value = Math.round((Math.random() * 10 - 4.2) + value);
      am5.time.add(date, "day", 1);
      return {
        date: date.getTime(),
        value: value
      };
    }
    
    function generateDatas(count) {
      var data = [];
      for (var i = 0; i < count; ++i) {
        data.push(generateData());
      }
      return data;
    }
    
    
    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    xAxis[props.name] = chart[props.name].xAxes.push(am5xy.DateAxis.new(root[props.name], {
      maxDeviation: 0.2,
      baseInterval: {
        timeUnit: "hour",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(root[props.name], {
        minorGridEnabled: true
      }),
      tooltip: am5.Tooltip.new(root[props.name], {})
    }));
    
    var sharedYAxis = chart[props.name].yAxes.push(am5xy.ValueAxis.new(root[props.name], {
      renderer: am5xy.AxisRendererY.new(root[props.name], {
        stroke: colorSet.getIndex(7),
        strokeOpacity: 1,
      })
    }));
    
    
    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    props.data.forEach((data, index) => {
      console.log("datawithindex:", {data, index})
      let yAxis;
      let seriesColor = colorSet.getIndex(index);
      if (data.name === "Energy Consumption Without Rensair" || data.name === "Energy Consumption With Rensair") {
        yAxis = sharedYAxis;
      } else {
        var yRenderer = am5xy.AxisRendererY.new(root[props.name], {
          strokeOpacity: 1,
          opposite: chart[props.name].yAxes.length%2 == 1
        }) 
        yAxis = chart[props.name].yAxes.push(am5xy.ValueAxis.new(root[props.name], {
          renderer: yRenderer
        }));
        yAxis[props.name] = yAxis;
        if (chart[props.name].yAxes.indexOf(yAxis) > 0) {
          yAxis.set("syncWithAxis", chart[props.name].yAxes.getIndex(0));
        }
        yRenderer.grid.template.set("strokeOpacity", 0.05);
        yRenderer.labels.template.set("fill", seriesColor);
        yRenderer.setAll({
          stroke: seriesColor,
          strokeOpacity: 1,
          opacity: 1
        });
      }
      series = chart[props.name].series.push(am5xy.LineSeries.new(root[props.name], {
        name: data.name,
        xAxis: xAxis[props.name],
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        legendValueText: "{valueY}",
        tooltip: am5.Tooltip.new(root[props.name], {
          pointerOrientation: "horizontal",
          labelText: "{valueY}"
        }),
      }));

      series.strokes.template.setAll({
        strokeWidth: 3,
        stroke: seriesColor,
        strokeOpacity: 1,
      });

      series.set("fill", seriesColor);
    
      series.data.setAll(data.readings || []);
    
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();
    }) 
    
    
    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart[props.name].set("cursor", am5xy.XYCursor.new(root[props.name], {
      behavior: "none"
    }));
    cursor.lineY.set("visible", false);
    
    
    // // Add scrollbar
    // // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal"
    // }));
    
    // chart.set("scrollbarY", am5.Scrollbar.new(root, {
    //   orientation: "vertical"
    // }));
    
    
    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    legend[props.name] = chart[props.name].children.push(am5.Legend.new(root[props.name], {
      x: am5.percent(50),
      centerX: am5.percent(50),
      width: am5.p100,
      layout: am5.GridLayout.new(root[props.name], {
        fixedWidthGrid: false
      }),
      paddingLeft: 20,
      itemContainers: {
        width: am5.percent(150), // Adjust percentage as needed for better spacing
        minWidth: am5.percent(150), // Adjust percentage as needed for better spacing
        float: "left",
        paddingLeft: 12
      }
  

    }));
    
    // When legend item container is hovered, dim all the series except the hovered one
    legend[props.name].itemContainers.template.events.on("load", function(e) {
      chartSeries.strokes.template.setAll({
        strokeWidth: 3
      });
    })

    legend[props.name].itemContainers.template.events.on("pointerover", function(e) {
      var itemContainer = e.target;
    
      // As series list is data of a legend, dataContext is series
      var series = itemContainer.dataItem.dataContext;
    
      chart[props.name].series.each(function(chartSeries) {
        if (chartSeries != series) {
          chartSeries.strokes.template.setAll({
            strokeOpacity: 0.15,
            stroke: am5.color(0x000000)
          });
        } else {
          chartSeries.strokes.template.setAll({
            strokeWidth: 3
          });
        }
      })
    })
    
    // When legend item container is unhovered, make all series as they are
    legend[props.name].itemContainers.template.events.on("pointerout", function(e) {
      var itemContainer = e.target;
      var series = itemContainer.dataItem.dataContext;
    
      chart[props.name].series.each(function(chartSeries) {
        chartSeries.strokes.template.setAll({
          strokeOpacity: 1,
          stroke: chartSeries.get("fill")
        });
      });
    })
    
    legend[props.name].labels.template.setAll({
      fontSize: 14,
      letterSpacing: 3,
    });

    // Increase the size of the legend color box
    legend[props.name].markers.template.setAll({
      width: 20,
      height: 30
    });

    legend[props.name].valueLabels.template.setAll({
      fontSize: 13,
      fontWeight: "200",
      paddingLeft: 3
    });
    
    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend[props.name].data.setAll(chart[props.name].series.values);
    
    
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart[props.name].appear(1000, 100);

    return () => {
      root[props.name].dispose();
    };
  }, [props.name, props.data])

  return (
    <div
      style={{
        overflow: "hidden",
        // paddingBottom: "56.25%",
        position: "relative",
        height: "100%",
      }}
    >
      <div
        id={props.name}
        style={{
          position: "absolute",
          left: "0",
          top: "0",
          height: "100%",
          // maxHeight: "480px",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default TSChart